import React, { useState } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestFormModal } from "../../components/modalform";
import { RequestForm } from "../../components/form";
import { useMedia } from "use-media";
import { CustomerMobile } from "../workflow-automation-platform";
import { OnboardingSlider } from "../workflow-automation-platform";
import { OnboardingMobile } from "../workflow-automation-platform";

import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../assets/automate/home/automate_features_header.png");
const topImageMobile = require("../../assets/automate/home/automate_features_header.png");

const customerBanner = require("../../assets/automate/home/automate_clients_logo.svg");
const bannerImageMobile = require("../../assets/automate/home/automate_clients_logo_mobile.svg");

const Section2_1 = require("../../assets/automate/home/automate_features_sec_1.png");
const Section2_2 = require("../../assets/automate/home/automate_features_sec_2.png");
const Section2_3 = require("../../assets/automate/home/automate_features_sec_3.png");
const Section2_4 = require("../../assets/automate/home/automate_features_sec_4.png");
const Section2_5 = require("../../assets/automate/home/automate_features_sec_5.png");

const onboard_one = require("../../assets/automate/home/automate_onboarding_1.png");
const onboard_two = require("../../assets/automate/home/automate_onboarding_2.png");
const onboard_three = require("../../assets/automate/home/automate_onboarding_3.png");
const onboard_four = require("../../assets/automate/home/automate_onboarding_4.png");

const feature_icon_1 = require("../../assets/automate/home/overview_icon_1.svg");
const feature_icon_2 = require("../../assets/automate/home/overview_icon_2.svg");
const feature_icon_3 = require("../../assets/automate/home/overview_icon_3.svg");
const feature_icon_4 = require("../../assets/automate/home/overview_icon_4.svg");
const feature_icon_5 = require("../../assets/automate/home/overview_icon_5.svg");

const OnboardSlide = require("../../assets/automate/home/onboarding_1.png");
const OnboardSlide1 = require("../../assets/automate/home/onboarding_2.png");
const OnboardSlide2 = require("../../assets/automate/home/onboarding_3.png");
const OnboardSlide3 = require("../../assets/automate/home/onboarding_4.png");
const OnboardArrow = require("../../assets/images/onboarding/onboarding_arrow.svg");
const OnboardHere = require("../../assets/automate/home/here_arrow.png");

export default function FeatureHomePage() {
  const [plan, setPlan] = useState("");
  const [showThanksPage, setShowThanksPage] = useState(false);

  const isSmall = useMedia({ maxWidth: "520px" });

  const onboardingSliderWithClick = () => setPlan("homepage");
  const betaClick = () => setPlan("homepage");
  return (
    <>
      <TitleAndMetaTags
        title="Workativ Automate Features - No Code Workflow Automation Tool"
        description="Explore Workativ Automate Features, automation made easy for all users, across business functions and apps."
        keywords={["Workflow Automation"]}
        ogImage={topImage}
      />
      <Container>
        <section className="workflow_automation_wrapper workflow_autoamte_wrapper trigger_page automate_product new_automate_background feature-automate-new-workflow new-mobile-feature-design resolution_automate_first_automate_feature button_form_automate_popup automate_feature_workflow_23">
          <Layout
            backgroundColor={"bg_header_automation"}
            logoFor="AUTOMATE"
            product="AUTOMATE"
          >
            <div className="automate_homepage">
              {isSmall ? (
                <section className="float-left width-100 automate-home-header-main-section">
                  <div className="container automate-home-header-main-section-flex">
                    <h1 className="font-page-header mb-0">
                      Automating app workflows made easy
                    </h1>
                    <p className="font-section-normal-text mb-0">
                      Use our no-code workflow automation platform to build
                      simple to complex workflows easily in minutes.
                    </p>
                    <RequestForm
                      product="automate"
                      AutomateCTA
                      isFooterForm={false}
                    />
                    <p className="font-section-sub-header-small text-align-center color-black mb-1">
                      {" "}
                      Free sign up. Free to use.
                    </p>
                    <img src={topImageMobile}></img>
                  </div>
                </section>
              ) : (
                <RegisterSection
                  altImage={"Workflow Automation platform"}
                  rightImage={topImage}
                  backgroundColor={"bg_header_automation"}
                  bannerImage={customerBanner}
                  bannerImageMobile={bannerImageMobile}
                  additionalClassContainer={"margin-automate-header-logo"}
                  additionalClassBanner={
                    "automate_header_banner_client_logo_feature"
                  }
                  customerText={"Join leading innovators"}
                >
                  <RegisterSection.FormWithContent
                    product="automate"
                    content=""
                  >
                    <RegisterSection.MainHeader additionalClassHeader="header_automate_feature_new">
                      Automating app workflows made easy
                    </RegisterSection.MainHeader>
                    <RegisterSection.SubHeader additionalClassSubHeader="automate_workflow_head_paragraph">
                      Use our no-code workflow automation platform to build
                      simple to complex workflows easily in minutes.
                    </RegisterSection.SubHeader>
                  </RegisterSection.FormWithContent>
                </RegisterSection>
              )}
            </div>
            <Section1 onClick={() => setPlan("homepage")} />
            <Section2 onClick={() => setPlan("homepage")} />
            <Section3 onClick={() => setPlan("homepage")} />
            <Section4 onClick={() => setPlan("homepage")} />
            <Section5 onClick={() => setPlan("homepage")} />
            {isSmall ? <CustomerMobile /> : null}

            {isSmall ? (
              <OnboardingMobile
                onboardingSliderWithClick={betaClick}
                tabContent={tabsForOnBoarding_November}
                imagesToPreload={tabsForOnBoardingImagesDesktop_November}
              />
            ) : (
              <OnboardingSlider
                onboardingSliderWithClick={betaClick}
                tabContent={tabsForOnBoarding_November}
                imagesToPreload={tabsForOnBoardingImagesDesktop_November}
              />
            )}

            <div className="automate_homepage">
              <RequestForm
                product="automate"
                content="Start on our free plan, and scale up as you grow."
                AutomateCTA
                automateContent
                autoamteButton
                automateName
                isFooterForm={true}
              />
            </div>

            {plan == "" ? null : (
              <RequestFormModal
                setPlan={setPlan}
                plan={plan}
                showThanksPage={showThanksPage}
                setShowThanksPage={setShowThanksPage}
                product="automate"
              />
            )}
          </Layout>
        </section>
      </Container>
    </>
  );
}

function Section1(props) {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="feature-page-section-first-automate float-left">
      <div className="second-section-main-div container">
        <div className="features-top-flex-section">
          <div className="features-top-left-section col-md-5">
            <img loading="lazy" src={feature_icon_1}></img>
            <h2 className="font-section-sub-header color-light-gray">
              Simple and Intuitive
            </h2>
            <p className="font-section-normal-text-testimonials line-height-18">
              Create app integrations and workflows using simple UI interface.
              No coding or developer support required.
            </p>
            <button
              onClick={() => {
                props.onClick();
              }}
            >
              <span className="font-section-small-text-medium-apps-heading ">
                Get Started FREE
              </span>
            </button>
          </div>

          <div className="features-top-right-section col-md-7">
            {isSmall ? (
              <img loading="lazy" src={Section2_1}></img>
            ) : (
              <img loading="lazy" src={Section2_1}></img>
            )}
          </div>
        </div>

        <div className="features-bottom-flex-section">
          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              No Coding
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-1">
              Specially designed for non-technical and business users. Create
              app integrations in minutes without coding or developer help.
            </p>
          </div>

          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Simple UI Interface
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-2">
              Use our simple UI interface feature to trigger actions or move
              data between apps. Create complex workflows with ease.
            </p>
          </div>

          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Pre-Built Workflow Templates
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-3">
              Jump start your workflows with thousands of pre-built workflow
              templates for popular use cases.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
function Section2(props) {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="feature-page-section-second-automate float-left">
      <div className="second-section-main-div container">
        <div className="features-top-flex-section">
          <div className="features-top-left-section col-md-5">
            <img loading="lazy" src={feature_icon_2}></img>
            <h2 className="font-section-sub-header color-light-gray">
              Powerful Tools
            </h2>
            <p className="font-section-normal-text-testimonials line-height-18">
              Format data, add conditional logic, set approvals, or time
              intervals into your workflows.
            </p>
            <button
              onClick={() => {
                props.onClick();
              }}
            >
              <span className="font-section-small-text-medium-apps-heading ">
                Get Started FREE
              </span>
            </button>
          </div>

          <div className="features-top-right-section col-md-7">
            {isSmall ? (
              <img loading="lazy" src={Section2_2}></img>
            ) : (
              <img loading="lazy" src={Section2_2}></img>
            )}
          </div>
        </div>

        <div className="features-bottom-flex-section">
          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Multi-Step Workflows
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-1">
              Build multi-action workflows with apps of your choice. Use
              advanced logics to pass data from one app to the other.
            </p>
          </div>

          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Advanced Controls
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-2">
              Control and organize your data flow using conditional logics, time
              delay, data formatting, set approvals for workflows and more.
            </p>
          </div>

          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              On-prem App Connector
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-3">
              On-prem Connect is a secure connector to help you connect workativ
              to your on-prem applications behind your firewall seamlessly and
              securely without opening ports, so you can create and automate
              workflows between on-prem and cloud apps easily.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
function Section3(props) {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="feature-page-section-third-automate float-left">
      <div className="second-section-main-div container">
        <div className="features-top-flex-section">
          <div className="features-top-left-section col-md-5">
            <img loading="lazy" src={feature_icon_3}></img>
            <h2 className="font-section-sub-header color-light-gray">
              Workflow Triggers
            </h2>
            <p className="font-section-normal-text-testimonials line-height-18">
              Connect your apps and automate workflows via event or
              polling-based triggers between your favorite apps.
            </p>
            <button
              onClick={() => {
                props.onClick();
              }}
            >
              <span className="font-section-small-text-medium-apps-heading ">
                Get Started FREE
              </span>
            </button>
          </div>

          <div className="features-top-right-section col-md-7">
            {isSmall ? (
              <img loading="lazy" src={Section2_3}></img>
            ) : (
              <img loading="lazy" src={Section2_3}></img>
            )}
          </div>
        </div>

        <div className="features-bottom-flex-section">
          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Event Trigger
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-1">
              Set up event triggers to auto-respond to app-related events
              instantly. For various service based, lifecycle based or other
              critical events, you never miss a beat to take quick actions via
              workflow automations.
            </p>
          </div>

          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Polling Trigger
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-2">
              Remove manual efforts by using polling triggers which can
              repeatedly check for changes periodically, at regular intervals in
              third party apps to trigger workflow automations.
            </p>
          </div>

          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Webhook Trigger
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-3">
              Make work faster by using webhook triggers that can be set up
              easily on workativ platform, integrating your existing apps. Call
              workflows using webhooks from external app or your chatbot easily.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
function Section4(props) {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="feature-page-section-fourth-automate float-left">
      <div className="second-section-main-div container">
        <div className="features-top-flex-section">
          <div className="features-top-left-section col-md-5">
            <img loading="lazy" src={feature_icon_4}></img>
            <h2 className="font-section-sub-header color-light-gray">
              Logs & Analytics
            </h2>
            <p className="font-section-normal-text-testimonials line-height-18">
              Set email notifications when a workflow fails, and debug failed
              workflows with ease without the need for developers. Take
              corrective actions using detailed logs with meaningful error
              messages that are easy to understand.
            </p>
            <button
              onClick={() => {
                props.onClick();
              }}
            >
              <span className="font-section-small-text-medium-apps-heading ">
                Get Started FREE
              </span>
            </button>
          </div>

          <div className="features-top-right-section col-md-7">
            {isSmall ? (
              <img loading="lazy" src={Section2_4}></img>
            ) : (
              <img loading="lazy" src={Section2_4}></img>
            )}
          </div>
        </div>

        <div className="features-bottom-flex-section">
          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Dashboard Analytics
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-1">
              Workativ offers detailed analytics on both product usage and
              workflows as well. Dashboard offers various data points on product
              effectiveness such as – most run workflows, requests auto
              resolved, staff hours saved, cost saving etc.
            </p>
          </div>

          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Error Handling
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-2">
              Workativ’s advanced error handling will help you handle errors
              that may occur during workflow executions. And you may set
              conditions, and or notifications that will help take next best
              workflow action to either overcome and move forward or report to
              the admin.
            </p>
          </div>

          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Workflow Testing
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-3">
              Testing is critical to workflows to ensure they work at each step.
              Using workativ’s testing capability you can test workflows with
              live data at every step of the workflow to ensure your workflows
              are in order.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
function Section5(props) {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section
      className={`feature-page-section-five-automate float-left ${
        isSmall ? "" : "mb-0"
      }`}
    >
      <div className="second-section-main-div container">
        <div className="features-top-flex-section">
          <div className="features-top-left-section col-md-5">
            <img loading="lazy" src={feature_icon_5}></img>
            <h2 className="font-section-sub-header color-light-gray">
              Secure & Reliable
            </h2>
            <p className="font-section-normal-text-testimonials line-height-18">
              Data encryption at rest and transit, data retention controls and
              audit logs. ISO 27001 certified.
            </p>
            <button
              onClick={() => {
                props.onClick();
              }}
            >
              <span className="font-section-small-text-medium-apps-heading ">
                Get Started FREE
              </span>
            </button>
          </div>

          <div className="features-top-right-section col-md-7">
            {isSmall ? (
              <img loading="lazy" src={Section2_5}></img>
            ) : (
              <img loading="lazy" src={Section2_5}></img>
            )}
          </div>
        </div>

        <div className="features-bottom-flex-section">
          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Data Encryption
            </h3>
            <p
              className={`font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-1 ${
                isSmall ? "" : "mb-0 pb-0"
              }`}
            >
              Runs fully on HTTPS. Best-in-class encryption of data in rest and
              transit. Your application credentials are double-encrypted.
            </p>
          </div>

          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Data Masking
            </h3>
            <p
              className={`font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-2 ${
                isSmall ? "" : "mb-0 pb-0"
              }`}
            >
              Confidently share workspace with authorized users, such as admin
              and testers, without exposing sensitive data.
            </p>
          </div>

          <div className="col-md-4">
            <h3 className="font-section-sub-header-small color-light-gray">
              Data controls
            </h3>
            <p
              className={`font-section-normal-text-testimonials line-height-18 feature-paragraph-automate-3 ${
                isSmall ? "" : "mb-0 pb-0"
              }`}
            >
              Set custom data retention policies for sensitive data. Access
              history of user activity and audit logs for compliance.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

const tabsForOnBoarding_November = [
  {
    header: "Sign up",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Sign up</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Select how your workflows are triggered and set app actions
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_one} alt="Sign up"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Sign up</h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
          Select how your workflows are triggered and set app actions
        </p>
        <img src={onboard_one} alt="Sign up"></img>
      </>
    ),
  },
  {
    header: "Create Workflows",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Create Workflows
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Select how your workflows are triggered and set app actions
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_two} alt="Bot Marketplace"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Create Workflows</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Select how your workflows are triggered and set app actions
        </p>

        <img src={onboard_two} alt="Bot Marketplace"></img>
      </>
    ),
  },
  {
    header: "Connect Apps",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Connect Apps</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Connect your workflows with apps account securely
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_three} alt="Test your Bot"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Connect Apps</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Connect your workflows with apps account securely
        </p>
        <img src={onboard_three} alt="Test your Bot"></img>
      </>
    ),
  },
  {
    header: "Active & Go-live",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Active & Go-live
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Test and activate your workflows to go-live seamlessly
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_four} alt="Connect App Workflows"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Active & Go-live</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Test and activate your workflows to go-live seamlessly
        </p>

        <img src={onboard_four} alt="Connect App Workflows"></img>
      </>
    ),
  },
];
const tabsForOnBoardingImagesDesktop_November = [
  onboard_one,
  onboard_two,
  onboard_three,
  onboard_four,
];

const initailizeOnboardoingSliderData = (betaClick) => ({
  header: {
    desktop: (
      <h2 className="font-section-header">
        Go live in minutes with our onscreen onboarding
      </h2>
    ),
    mobile: (
      <h3 className="font-section-header">
        Go live in minutes with our onscreen onboarding
      </h3>
    ),
  },
  subHeader: {
    desktop: (
      <h5 className="font-section-normal-text-medium sign_up_font_size_reduce color-black">
        Sign-up for beta&nbsp;
        <a
          style={{ color: "#1857DC", cursor: "pointer" }}
          onClick={betaClick}
          className="automate_onboard_here_signup"
        >
          {" "}
          <span> here </span>
          <img
            loading="lazy"
            src={OnboardHere}
            className="onboard_here_arrow_automate"
          ></img>
        </a>
      </h5>
    ),
    mobile: (
      <h5 className="font-section-normal-text-medium sign_up_font_size_reduce color-black">
        Sign-up for beta{" "}
        <a
          style={{ color: "#1857DC", cursor: "pointer" }}
          onClick={betaClick}
          className="automate_onboard_here_signup"
        >
          {" "}
          <span> here </span>
          <img
            loading="lazy"
            src={OnboardHere}
            className="onboard_here_arrow_automate"
          ></img>
        </a>
      </h5>
    ),
  },
  sliders: [
    {
      title: "Sign up",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img loading="lazy" src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Sign up
              </h3>
              <p className="responsive-p-slider  automate_onboarding_home_new font-section-normal-text">
                Create a new workflow or download pre-built workflows from
                marketplace
              </p>
            </div>
          </div>

          <div className="right_section">
            <img loading="lazy" src={OnboardSlide} alt="sign_up"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Sign up</h3>
            <p className="font-section-normal-text automate_onboarding_home_new">
              Create a new workflow or download pre-built workflows from
              marketplace
            </p>
          </div>

          <div className="image_section">
            <img loading="lazy" src={OnboardSlide} alt="sign_up"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Create Workflows",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img loading="lazy" src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Create Workflows
              </h3>
              <p className="responsive-p-slider  automate_onboarding_home_new font-section-normal-text">
                Select how your workflows are triggered and set app actions
              </p>
            </div>
          </div>

          <div className="right_section">
            <img
              loading="lazy"
              src={OnboardSlide1}
              alt="create_workflows"
            ></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Create Workflows</h3>
            <p className="font-section-normal-text automate_onboarding_home_new">
              Select how your workflows are triggered and set app actions
            </p>
          </div>

          <div className="image_section">
            <img
              loading="lazy"
              src={OnboardSlide1}
              alt="create_workflows"
            ></img>
          </div>
        </div>
      ),
    },
    {
      title: "Connect Apps",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img loading="lazy" src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Connect Apps
              </h3>
              <p className="responsive-p-slider font-section-normal-text  automate_onboarding_home_new">
                Connect your workflows with apps account securely
              </p>
            </div>
          </div>

          <div className="right_section">
            <img
              loading="lazy"
              src={OnboardSlide2}
              alt="connect_app_work"
            ></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Connect Apps</h3>
            <p className="font-section-normal-text automate_onboarding_home_new">
              Connect your workflows with apps account securely
            </p>
          </div>

          <div className="image_section">
            <img
              loading="lazy"
              src={OnboardSlide2}
              alt="connect_app_work"
            ></img>
          </div>
        </div>
      ),
    },
    {
      title: "Active & Go-live",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img loading="lazy" src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Active & Go-live
              </h3>
              <p className="responsive-p-slider automate_onboarding_home_new font-section-normal-text">
                Test and activate your workflows to go-live seamlessly
              </p>
            </div>
          </div>
          <div className="right_section">
            <img loading="lazy" src={OnboardSlide3} alt="go_live"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Active & Go-live</h3>
            <p className="font-section-normal-text automate_onboarding_home_new">
              Test and activate your workflows to go-live seamlessly
            </p>
          </div>

          <div className="image_section">
            <img loading="lazy" src={OnboardSlide3} alt="go_live"></img>
          </div>
        </div>
      ),
    },
  ],
});
